var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,3681283568)}),_c('v-card',{staticClass:"mb-4"},[(_vm.products.length === 0)?_c('v-card-title',[_vm._v(" Ingen produkter fundet ")]):_vm._e(),_c('v-container',[_c('v-row',_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"12","lg":"3","md":"3","xl":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-card',{attrs:{"elevation":hover ? 12 : 2,"to":{ 
                    name: 'product', 
                    params: { 
                      categoryNameUriFormatted: _vm.category.nameUriformatted, 
                      productNameUriFormatted: product.nameUriformatted, 
                      productId: product.id }}}},[_c('v-img',{staticClass:"white--text",attrs:{"aspect-ratio":"1","src":_vm.getDownloadUrlOfPrimaryProductImage(product)}},[(_vm.isProductSold(product))?_c('v-chip',{staticClass:"ma-4",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Solgt ")]):_vm._e()],1),_c('v-card-title',[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"flex-grow-1"}),(product.price && !_vm.isProductSold(product))?_c('span',{staticClass:"subtitle-1 grey--text text--darken-1"},[_vm._v(_vm._s(product.price)+"kr")]):_vm._e()])],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }