




























































import Vue from 'vue';
import { Category } from '../../../shared/src/entities/Category';
import { Product } from '../../../shared/src/entities/Product';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import { IMetadataService } from '../../../shared/src/services/IMetadataService';
import FilterDrawer from '../components/FilterDrawer.vue';
import { ProductStatus } from '../../../shared/src/entities/ProductStatus';

export default Vue.extend({
  components: {
    FilterDrawer
  },
  inject: {
    categoryDataservice: 'categoryDataservice',
    productDataservice: 'productDataservice',
    settingsDataservice: 'settingsDataservice',
    metadataService: 'metadataService'
  },
  data() {
    return {
      showSoldItems: true
    };
  },
  methods: {
    getDownloadUrlOfPrimaryProductImage(product: Product): string | undefined {
      if (!product || !product.images ) {
        return '';
      }

      const primaryImage = product.images.get(0);

      if (!primaryImage) {
        return '';
      }

      return primaryImage.smallImagedownloadUrl;
    },
    showSoldItemsChanged(showSoldItems: boolean): void {
      this.showSoldItems = showSoldItems;
    },
    isProductSold(product: Product) {
      if (!product) {
        return false;
      }

      return product.status === ProductStatus.SOLD;
    }
  },
  watch: {
    pagename: {
      immediate: true,
      handler(newValue): void {
        ((this as any).metadataService as IMetadataService).setTitle(newValue);
      }
    },
    description: {
      immediate: true,
      handler(newValue): void {
        return ((this as any).metadataService as IMetadataService).setDescription(newValue);
      }
    }
  },
  computed: {
    categories(): Category[] {
      return ((this as any).categoryDataservice as ICategoryDataservice).categories as Category[];
    },
    pagename(): string {
      if (!this.category) {
        return ((this as any).settingsDataservice as ISettingsDataservice).shopName;
      }

      return this.category.name + ' - ' + ((this as any).settingsDataservice as ISettingsDataservice).shopName;
    },
    category(): Category | undefined {
      return ((this as any).categoryDataservice as ICategoryDataservice).getCategoryByUriFormattedName(
        this.$route.params.categoryNameUriFormatted);
    },
    products(): Product[] {
      if (!this.category ) {
        return [];
      }

      const allProducts = ((this as any).productDataservice as IProductDataservice)
      .getProductsForCategory(this.category.id, [ProductStatus.FOR_SALE, ProductStatus.SOLD, ProductStatus.RESERVED]);

      return ((this as any).productDataservice as IProductDataservice).sortProductsByStatus(allProducts);
    },
    description(): string {
      if (!this.category) {
        return '';
      }
      return this.category.description;
    },
    breadcrumbs(): any[] {
      if (!this.category) {
        return [
        {
          text: 'Forside',
          disabled: false,
          href: '/',
        }];
      }

      return [
        {
          text: 'Forside',
          disabled: false,
          to: {name: 'home'},
          exact: true
        },
        {
          text: this.category.name,
          disabled: true,
        },
      ];
    }
  },
});
