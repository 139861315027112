














import Vue from 'vue';

export default Vue.extend({
  props: ['showSoldItems', 'visible'],
  methods: {
    showSoldItemsChanged(showSoldItems: boolean) {
      this.$emit('showSoldItems', showSoldItems);
    }
  }
});
